import React, { useEffect, useState } from 'react';
import '../css/main.css';
import '../css/base-styles.css';


function App() {
  const pages = ['about', 'work', 'joinus', 'contact'];

  const mainImages = ['we_make_websites.png', 'weve_made_websites.png', 'help_us_make_websites.png', 'need_a_website.png']

  const [siteEntered, setSiteEntered] = useState(false);

  const [enterImage, setEnterImage] = useState('enter.png');

  const [buttonImages, setButtonImages] = useState(['_default.png', '_default.png', '_default.png', '_hovered.png']);

  const [selectedIndex, setSelectedIndex] = useState(3);

  const [logoCounter, setLogoCounter] = useState(0);

  const imageHover = (index) => {
    if (selectedIndex === index && buttonImages.includes('_hovered.png')) {
      return;
    } else {
      let currImages = buttonImages;
      currImages[index] = currImages[index] === '_default.png' ? '_hovered.png' : '_default.png';
      setButtonImages([...currImages]);
    }
  }

  const navClick = (index) => {
    let currImages = ['_default.png', '_default.png', '_default.png', '_default.png'];
    currImages[index] = '_hovered.png';
    setButtonImages([...currImages]);
    setSelectedIndex(index);
  }

  const openSite = () => {
    setSiteEntered(true);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setLogoCounter(logoCounter => (logoCounter + 1) % 4);
    }, 1000);

    return () => clearInterval(timer);
  });

  return (
    <div className="App">
      {
        siteEntered ?
        <div className="Ta(c) W(95%) H(80%) Mx(a) Pt(15px)">
          <div>
          <img alt="Spector Labs" src={`logo_${logoCounter}.png`} width="20%" className="Cur(p)" />

          {
            pages.map((page, it) => {
              return <img width='20%'
                          className="Cur(p)"
                          src={page + buttonImages[it]}
                          onMouseEnter={() => imageHover(it)}
                          onMouseLeave={() => imageHover(it)}
                          onClick={() => navClick(it)}
                          key={page}
                          alt={page}
                      />
            })
          }
          </div>
          <div>
            <a rel="noopener noreferrer" target="_blank" href="mailto:matt@spectorlabs.dev?Subject=I%20need%20a%20website!">
              <img alt={mainImages[selectedIndex].replace('.png', '').replace('_', ' ')} className="Mt(50px)" src={mainImages[selectedIndex]} width="80%" />
            </a>
          </div>
        </div>
        :
        <div className="Ta(c)" id="image-main">
            <div className="H(80%) W(100%) Pt(50px)"
                 onClick={() => openSite()}
              >
                <img alt="Beaker" height="70%" src="beaker.gif" id="beakerLoader" className="D(b) Mx(a) Cur(p) Pb(15px)" />
                <div
                  className="W(100%) H(20%) Cur(p) enterLogo"
                  onMouseOver={() => setEnterImage('good_choice.png')}
                  onMouseOut={() => setEnterImage('enter.png')}
                >
                  <img alt="Enter Site" height="100%" src={enterImage} className="D(b) Mx(a) Cur(p)" />
                </div>
            </div>
        </div>

      }

    </div>
  );
}

export default App;
